import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import CircledArrow from 'icons/circled-arrow.svg';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import { ACADEMY_KIDS_LANDING } from 'shared/routes';
import { getUrlByLanguage } from 'utilities';

const KidsPromo = ({ description, linkName, title }) => {
  const languageContext = useContext(GlobalStateContext);
  const currentLanguageId = languageContext.language.id;

  return (
    <section className="kids-promo inverted">
      <div className="kids-promo__frame">
        <div className="kids-promo__card">
          <h3 className="kids-promo__title">{title}</h3>
          {description && description !== '' && (
            <div
              className="kids-promo__description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
          <Link
            className="kids-promo__link btn-link btn-link--circled-arrow"
            to={getUrlByLanguage(currentLanguageId, ACADEMY_KIDS_LANDING)}
          >
            {linkName}
            <CircledArrow />
          </Link>
        </div>
      </div>
    </section>
  );
};

KidsPromo.propTypes = {
  description: PropTypes.string.isRequired,
  linkName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export { KidsPromo };
