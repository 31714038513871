import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { GlobalStateContext } from 'containers/GlobalContextProvider';
import generateUniqueId from 'utilities/generateUniqueId';
import { VideoPlayer } from 'components/videoPlayer';
import { Image } from 'components/conditionalImage';
import IconPlayButton from 'icons/play-button.svg';
import './scss/video-gallery.scss';

const VideoGallery = ({ title, videos, className }) => {
  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  const [activeVideo, setActiveVideo] = useState(0);
  const [hasVideoStarted, setVideoStarted] = useState(false);

  const handleActiveVideo = (index) => {
    setActiveVideo(index);
    setVideoStarted(false);
  };

  return (
    videos &&
    videos.length > 0 && (
      <div className={classNames('video-gallery', className && `${className}`)}>
        <div className="video-gallery__frame">
          {title && <h2 className="video-gallery__section-title">{title}</h2>}
          <div className="video-gallery__video">
            <VideoPlayer
              className="video-gallery__player"
              videoId={videos[activeVideo].videoID}
              activeVideoTitle={videos[activeVideo].title}
              hasVideoStarted={hasVideoStarted}
              setVideoStarted={setVideoStarted}
            />
          </div>
          <div className="video-gallery__row-wrapper">
            <div className="video-gallery__row">
              {videos.map((video, index) => (
                <div
                  className={classNames(
                    'video-gallery__column',
                    videos.length === 4 && 'video-gallery__column--four',
                    videos.length === 5 && 'video-gallery__column--five'
                  )}
                  key={generateUniqueId()}
                >
                  <div
                    className={classNames(
                      'video-gallery__tile',
                      activeVideo === index && 'active'
                    )}
                  >
                    <div className="video-gallery__thumbnail">
                      <Image
                        image={
                          video.thumbnail && video.thumbnail.children[0].fluid
                        }
                        imageText={video.title}
                        className="video-gallery__image"
                      />
                      <button
                        type="button"
                        className="video-gallery__button"
                        onClick={() => handleActiveVideo(index)}
                      >
                        <IconPlayButton className="video-gallery__icon" />
                        {translation['button.playVideo']}
                      </button>
                    </div>
                    <h3 className="video-gallery__title">{video.title}</h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

VideoGallery.propTypes = {
  className: PropTypes.string,
  videos: PropTypes.arrayOf(PropTypes.shape()),
  title: PropTypes.string,
};

export { VideoGallery };
