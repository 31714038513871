import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import './scss/styles.scss';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import { languageOptions } from 'lang';
import { Layout } from 'layout';
import { HeroBannerInfo } from 'components/heroBanner';
import { contactInfo } from 'utilities';
import { AcademyContacts } from 'components/academyContacts';
import { CMSLink } from 'components/cmsLink';
import { VideoGallery } from 'components/videoGallery';
import { TestimonialsSlider } from 'components/testimonialsSlider';
import { KidsPromo, MemoSection, CtaSection } from './components';

const AcademyKidsMentors = ({ data }) => {
  const { markdownRemark } = data;
  const frontmatter = markdownRemark.frontmatter;
  const hasLanguageDropdown = Boolean(markdownRemark.frontmatter.lang);

  const languageContext = useContext(GlobalStateContext);
  languageContext.setLanguage(
    languageOptions.find(
      (lang) => lang.id === markdownRemark.frontmatter.lang.toLowerCase()
    )
  );
  const translation = languageContext.dictionary.translations;

  const contactsAndLocations = contactInfo()[0];
  const academyMentors = contactsAndLocations.frontmatter.contacts.find(
    (contact) => contact.academy === 'Kids - Mentors'
  );

  const renderControls = () => (
    <CMSLink className="btn" linkUrl={frontmatter.registrationLink}>
      {frontmatter.registrationLinkText}
    </CMSLink>
  );

  return (
    <Layout
      darkTheme
      metaTitle={
        markdownRemark.frontmatter.metaInformation.metaTitle ||
        markdownRemark.frontmatter.title
      }
      metaDescription={
        markdownRemark.frontmatter.metaInformation.metaDescription || null
      }
      metaKeywords={
        markdownRemark.frontmatter.metaInformation.metaKeywords || null
      }
      metaImage={
        markdownRemark.frontmatter.metaInformation.metaImage
          ? markdownRemark.frontmatter.metaInformation.metaImage.children[0]
              .fixed.src
          : null
      }
      hasLanguageDropdown={hasLanguageDropdown}
    >
      <HeroBannerInfo
        backgroundImage={
          frontmatter.heroBg && frontmatter.heroBg.children[0].fluid.src
        }
        description={frontmatter.shortDescription}
        renderControls={renderControls}
        title={frontmatter.title}
      />
      <KidsPromo
        description={frontmatter.kidsPromo.description}
        linkName={frontmatter.kidsPromo.linkName}
        title={frontmatter.kidsPromo.title}
      />
      <MemoSection
        className="inverted"
        content={frontmatter.memoBlock.content}
        memoImage={frontmatter.memoBlock.memoImage}
        title={frontmatter.memoBlock.title}
      />
      <VideoGallery
        className="inverted"
        title={frontmatter.videoGalleryMentors.title}
        videos={frontmatter.videoGalleryMentors.videos}
      />
      <TestimonialsSlider testimonialsArray={frontmatter.testimonials} />
      <CtaSection
        className="inverted"
        linkName={frontmatter.cta.linkName}
        linkUrl={frontmatter.cta.linkUrl}
        title={frontmatter.cta.title}
        description={frontmatter.cta.description}
      />
      <AcademyContacts
        className="academy-contacts--wrapper"
        email={academyMentors.email}
        phone={academyMentors.phone}
        trademark={translation['contacts.trademark']}
      />
    </Layout>
  );
};

AcademyKidsMentors.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape(),
  }),
};

export const AcademyKidsMentorsQuery = graphql`
  query AcademyKidsMentors($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        metaInformation {
          metaTitle
          metaDescription
          metaKeywords
          metaImage {
            children {
              ... on ImageSharp {
                fixed(width: 1080, height: 1080) {
                  src
                }
              }
            }
          }
        }
        lang
        title
        shortDescription
        registrationLink
        registrationLinkText
        heroBg {
          children {
            ... on ImageSharp {
              fluid(maxWidth: 2397, maxHeight: 1599) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        kidsPromo {
          title
          description
          linkName
        }
        memoBlock {
          title
          memoImage {
            image {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 1598, maxHeight: 1066) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            imageDescription
          }
          content
        }
        videoGalleryMentors {
          title
          videos {
            title
            thumbnail {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 799, maxHeight: 533) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            videoID
          }
        }
        testimonials
        cta {
          title
          description
          linkName
          linkUrl
        }
      }
    }
  }
`;

export default AcademyKidsMentors;
