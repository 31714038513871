import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CMSLink } from 'components/cmsLink';

const CtaSection = ({ className, linkName, linkUrl, title, description }) => (
  <section className={classNames('cta-section', className && `${className}`)}>
    <div className="cta-section__frame">
      <h2 className="cta-section__title">{title}</h2>
      {description && <p className="cta-section__desc">{description}</p>}
      <CMSLink className="btn" linkUrl={linkUrl}>
        {linkName}
      </CMSLink>
    </div>
  </section>
);

CtaSection.propTypes = {
  className: PropTypes.string,
  linkName: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export { CtaSection };
