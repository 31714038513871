import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Image } from 'components/conditionalImage';

const MemoSection = ({ className, content, memoImage, title }) => (
  <section className={classNames('memo-section', className && `${className}`)}>
    <div className="memo-section__frame">
      <h2 className="memo-section__title">{title}</h2>
      <div className="memo-section__content-wrapper">
        <Image
          image={memoImage.image && memoImage.image.children[0].fluid}
          imageText={memoImage.imageDescription}
          className="memo-section__image"
        />
        {content && content !== '' && (
          <div
            className="memo-section__content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
      </div>
    </div>
  </section>
);

MemoSection.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string.isRequired,
  memoImage: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object]),
    imageDescription: PropTypes.string.isRequired,
  }),
  title: PropTypes.string.isRequired,
};

export { MemoSection };
